<template>
  <div class="body">
    <div class="head">
      <div class="left">体有所建</div>
      <div class="right">牵头单位：路桥区文广旅体局</div>
    </div>
        <div class="btn">
		
      <div
        :class="item.index == indexA ? 'active' : ''"
        v-for="(item, index) in btnItem"
        :key="index"
        :style="item.index == 3 ? 'color:#DBDBDB' : ''"
        @click="handle(item.index)"
      >
        <el-tooltip
          placement="top"
          effect="light"
          v-if="index == 3"
          content="建设中"
          ><div>{{ item.text }}</div></el-tooltip
        >
        <div v-else>
          {{ item.text }}
        </div>
      </div>
    </div>

    <div
      v-if="indexA == 0"
      style="display: flex; flex-wrap: wrap; justify-content: space-between"
    >
      <div class="culture">
        <div class="title">
          <div class="titleDiv">
            <span></span>
            <div>人数统计</div>
          </div>
          <el-tooltip placement="top" effect="light">
            <div slot="content">
              {{ time1 }}<br />{{ time2 }}<br />更新频率：每季度
            </div>
            <img src="../assets/闹钟.png" @mouseenter="mouseenter" />
          </el-tooltip>
        </div>
        <div
          class="cultureItem"
          v-for="(item, index) in cultural2"
          :key="index"
        >
          <div>
            <span>{{ item.text }}</span>
            <span class="span" v-if="item.btn" @click="btn(item.text, index)"
              ><i class="iconfont icon-shujufenxi"></i
              >{{ " " + item.btn }}</span
            >
          </div>
          <div>
            {{ item.num }}<span class="company">{{ item.company }}</span>
          </div>
          <div v-if="item.trend">
            <span style="font-size:12px;margin-right:3px">{{item.txt}}</span>
            <span style="font-size:12px;color:#f96c70" v-if="item.trend.type == 0">{{item.trend.text}}</span>
            <span style="font-size:12px;color:#30c566" v-if="item.trend.type == 1">{{item.trend.text}}</span>
          </div>
        </div>
        <!-- <div style="display: flex; justify-content: center">
          <el-pagination
            background
            layout="prev, pager, next"
            :page-size="3"
            :total="6"
            small
            @current-change="change"
          >
          </el-pagination>
        </div> -->
      </div>
      
      <div class="culture">
        <div class="title">
          <div class="titleDiv">
            <span></span>
            <div>奖牌数量</div>
          </div>
          <el-tooltip placement="top" effect="light">
            <div slot="content">
              {{ time1 }}<br />{{ time2 }}<br />更新频率：每季度
            </div>
            <img src="../assets/闹钟.png" @mouseenter="mouseenter" />
          </el-tooltip>
        </div>
        <div
          class="cultureItem"
          v-for="(item, index) in medal"
          :key="index"
        >
          <div>
            <span>{{ item.text }}</span>
            <span class="span" v-if="item.btn3" @click="btn3(item.text,item.total,item.text,index)"
              ><i class="iconfont icon-17yanjing2"></i
              >{{ " " + item.btn3 }}</span
            >
            <span class="span" v-if="item.btn" @click="btn2(item.text,item.total,item.text,index)"
              ><i class="iconfont icon-17yanjing2"></i
              >{{ " " + item.btn }}</span
            >
          </div>
          <div>
            {{ item.num }}<span class="company">{{ item.company }}</span>
          </div>
          <div v-if="item.trend">
            <span style="font-size:12px;margin-right:3px">{{item.txt}}</span>
            <span style="font-size:12px;color:#f96c70" v-if="item.trend.type == 0">{{item.trend.text}}</span>
            <span style="font-size:12px;color:#30c566" v-if="item.trend.type == 1">{{item.trend.text}}</span>
          </div>
        </div>
      </div>
    
     <div class="culture">
        <div class="title">
          <div class="titleDiv">
            <span></span>
            <div>基期值</div>
          </div>
          <el-tooltip placement="top" effect="light">
            <div slot="content">
              {{ time1 }}<br />{{ time2 }}<br />更新频率：每季度
            </div>
            <img src="../assets/闹钟.png" @mouseenter="mouseenter" />
          </el-tooltip>
        </div>
        <div
          class="cultureItem"
          v-for="(item, index) in period2"
          :key="index"
        >
          <div>
            <span>{{ item.text }}</span>
            <span class="span" v-if="item.btn4" @click="btn4(item.text,item.total,item.text,index)"
              ><i class="iconfont icon-17yanjing2"></i
              >{{ " " + item.btn4 }}</span
            >
          </div>
          <div style="margin-top:10px">
            {{ item.num }}<span class="company">{{ item.company }}</span>
          </div>
          <div v-if="item.txt">
            <span style="font-size:12px;margin-right:3px">{{item.txt}}</span>
            <div v-if="item.trend" style="display:inline-block">
              <span style="font-size:12px;color:#f96c70" v-if="item.trend.type == 0">{{item.trend.text}}</span>
            <span style="font-size:12px;color:#30c566" v-if="item.trend.type == 1">{{item.trend.text}}</span>
            </div>
          </div>
        </div>
         <!-- <div style="display: flex; justify-content: center">
          <el-pagination
            background
            layout="prev, pager, next"
            :page-size="3"
            :total="4"
            small
            @current-change="change"
          >
          </el-pagination>
        </div> -->
      </div>
      



      <el-dialog
        title="社会体育指导员数量(看走势)"
        :visible.sync="dialogVisible"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="70%"
      >
        <div ref="chart" style="width: 640px; height: 303px"></div>
      </el-dialog>
      <el-dialog
        title="青少年运动员注册数量(看走势)"
        :visible.sync="dialogVisible2"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="70%"
      >
        <div ref="chart2" style="width: 640px; height: 303px"></div>
      </el-dialog>
    
      <el-dialog
        :title="title"
        :visible.sync="dialogMedal"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="70%"
      >
        <div style="color:#000;font-size:14px">{{oText}}</div>
        <div style="color:#1492ff;font-size:12px"><span >{{totalTitle}}</span></div>
      </el-dialog>


       <!-- <el-dialog
        :title="title"
        :visible.sync="dialogVisible3"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="70%"
      >
        <div class="dialogVisible5_box">
          <div class="dialogVisible5_title">
            <div class="dialogVisible5_t1">国家级</div>
            <div class="dialogVisible5_t2">总个数12枚</div>
          </div>
          <div class="dialogVisible5_title">
            <div class="dialogVisible5_t1">省级</div>
            <div class="dialogVisible5_t2">总个数70枚</div>
          </div>
          <div class="dialogVisible5_title">
            <div class="dialogVisible5_t1">省运会周期累计金牌数</div>
            <div class="dialogVisible5_t2">总个数3枚</div>
          </div>
         
        </div>
      </el-dialog> -->

        <el-dialog
        title="奖牌数量"
        :visible.sync="dialogVisible3"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="80%"
      >
        <div ref="chart3" style="width: 100%; height: 303px"></div>
      </el-dialog>

       <el-dialog
        :title="title"
        :visible.sync="dialogVisible4"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="80%"
      >
        <div style="width:100%">
          <div style="width:100%;display:flex;justify-content: space-between;flex-wrap:wrap">
            <div style="display:flex;background:#dcefff;width:48%;height:70px;text-align:center;line-height:70px;margin-top:25px">
              <div style="font-size:16px;font-weight:bold;margin-left:15px;">人均体育场面积</div>
              <div style="font-size:14px;font-weight:bold;margin-left:30px;color:#158fff">1.28平方米</div>
            </div>
            <div style="display:flex;background:#dcefff;width:48%;height:70px;text-align:center;line-height:70px;margin-top:25px">
               <div style="font-size:16px;font-weight:bold;margin-left:15px;">经常锻炼人数比例（学生除外）</div>
              <div style="font-size:14px;font-weight:bold;margin-left:30px;color:#158fff">28.4%</div>
            </div>
            <div style="display:flex;background:#dcefff;width:48%;height:70px;text-align:center;line-height:70px;margin-top:25px">
               <div style="font-size:16px;font-weight:bold;margin-left:15px;">国民体质检测合格率</div>
              <div style="font-size:14px;font-weight:bold;margin-left:30px;color:#158fff">93.5%</div>
            </div>
            <div style="display:flex;background:#dcefff;width:48%;height:70px;text-align:center;line-height:70px;margin-top:25px">
               <div style="font-size:16px;font-weight:bold;margin-left:15px;">社会体育组织</div>
              <div style="font-size:14px;font-weight:bold;margin-left:30px;color:#158fff">35个</div>
            </div>
          </div>

        </div>
      </el-dialog>

    </div>

    <div v-if="indexA == 1" class="culture" style="width: 100%; height: 590px">
      <div class="title">
        <div class="titleDiv">
          <span></span>
          <div>体有所建地图</div>
        </div>
        <el-tooltip placement="top" effect="light">
          <div slot="content">
            {{ time1 }}<br />{{ time2 }}<br />更新频率：每季度
          </div>
          <img src="../assets/闹钟.png" @mouseenter="mouseenter" />
        </el-tooltip>
      </div>
      <div>
        <div id="map" style="width: 100%; height: 1000px"></div>
      </div>
    </div>

       <div v-if="indexA == 2" class="culture" style="width: 928px; height: 500px">
      <div class="title">
        <div class="titleDiv">
          <span></span>
          <div>预警监测</div>
        </div>
        <el-tooltip placement="top" effect="light">
          <div slot="content">
            {{ time1 }}<br />{{ time2 }}<br />更新频率：每季度
          </div>
          <img src="../assets/闹钟.png" @mouseenter="mouseenter" />
        </el-tooltip>
      </div>
      <div>
        <div style="width: 928px; height: 400px" ref="ref"></div>
      </div>
    </div>

  </div>
</template>

<script>
import * as echarts from "echarts";
import { LabelsData } from "./map";

export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      dialogMedal:false,
      title:"",
      totalTitle:"",
      oText:"",
      btnItem: [
        { text: "核心业务", index: 0 },
        { text: "资源分布", index: 1 },
        { text: "监测预警", index: 2 },
        { text: "制度创新", index: 3 },
      ],
      indexA: 0,
      period: [
        // { text: "人均体育场面积", num: "1.28", company: "平方米",txt:"当年平均数据1.28平方米" },
        // { text: "经常锻炼人数比例（学生除外）", num: "28.4%"},
        // { text: "国民体质检测合格率", num: "93.5%"},
        // { text: "社会体育组织", num: "35", company: "个",txt:"当年累计3枚 同比",trend:{type:1,text:"↑0%"} },
      ],
      period2: [
        // { text: "人均体育场面积", num: "1.28", company: "平方米",txt:"当年平均数据1.28平方米" },
        // { text: "经常锻炼人数比例（学生除外）", num: "28.4%"},
        // { text: "国民体质检测合格率", num: "93.5%"},
        { text: "国民体质检测合格率", num: "93.5%",btn4: "看详情"},
      ],
     
      cultural2: [
        { text: "社会体育指导员数量", num: "345", company: "人" ,txt:"当年累计：345 同比",trend:{type:0,text:"↓ 43.34%"}, btn: "看趋势"},
        { text: "青少年运动员注册数量", num: "542", company: "人" ,txt:"当年累计542人 同比", trend:{type:1,text:"↑ 14.8%"},btn: "看趋势"},
      ],
      medal: [
        { text: "奖牌数量", num: "85", company: "枚" ,txt:"当年累计85枚 同比",trend:{type:1,text:"↑50%"}, btn3: "看详情",total:"总个数85枚"},
        // { text: "国家级", num: "12", company: "枚" ,txt:"当年累计12枚 同比",trend:{type:1,text:"↑10%"}, btn: "看详情",total:"总个数12枚"},
        // { text: "省级", num: "70", company: "枚" ,txt:"当年累计70枚 同比",trend:{type:1,text:"↑120%"}, btn: "看趋势",total:"总个数70枚"},
        // { text: "省运会周期累计金牌数", num: "3", company: "枚" ,txt:"当年累计3枚 同比",trend:{type:1,text:"↑0%"}, btn: "看趋势",total:"总个数3枚"},
        
      ],
      show: true,
      nowTime: null,
      time1: "",
      time2: "",
      label1: "",
      label2: "",
    };
  },
  created() {
    const isZzd = JSON.stringify(navigator.userAgent).indexOf("TaurusApp") > -1;
    if (!isZzd) {
      window.location.href =
        "https://dgov-integrate.jinhua.gov.cn:5443/szjh-gov-pc/#/404";
    }
  },
  mounted() {
    // this.init();
  },
  watch: {
    dialogVisible(e) {
      if (e) {
        setTimeout(() => {
          this.init();
        }, 1);
      }
    },
    dialogVisible2(e) {
      if (e) {
        setTimeout(() => {
          this.init2();
        }, 1);
      }
    },
    dialogVisible3(e) {
      if (e) {
        setTimeout(() => {
          this.init3();
        }, 1);
      }
    },
  },
  methods: {
    listenResize() {
      setTimeout(() => {
        window.onresize = () => {
          this.init();
          this.init2();
          this.init3();
        };
      }, 0);
    },
    mouseenter() {
      //获取当前时间并打印
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      // this.time1 = "数据范围：" + yy + "年" + "1月-" + mm + "月";
      this.time1 = "数据范围：截止2022年1月";
      this.time2 = "更新时间：" + yy + "年" + mm + "月" + dd + "日";
    },
    nowSize(val, initWidth = 1554) {
      let nowClientWidth = document.documentElement.clientWidth;
      return val * (nowClientWidth / initWidth);
    },
    init() {
      this.chart = echarts.init(this.$refs.chart);
      let option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["2017", "2018", "2019", "2020"],
        },
        yAxis: {
          type: "value",
          scale: true,
          splitNumber: 3,
        },
        grid: {
          left: "15%",
        },
        tooltip: {
          trigger: "axis",
          formatter: "{c}人",
        },
        series: [
          {
            data: [140, 410, 609, 345],
            itemStyle: {
              color: "#39A2FF",
            },
            type: "line",
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: "#ECF6FF", // 0% 处的颜色
                },
                {
                  offset: 0.4,
                  color: "#ABD4F8", // 100% 处的颜色
                },
                {
                  offset: 0,
                  color: "#69B9FF", // 100% 处的颜色
                },
              ]),
            },
          },
        ],
      };
      this.chart.setOption(option);
    },
    init2() {
      this.chart = echarts.init(this.$refs.chart2);
      let option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["2016", "2017", "2018", "2019", "2020"],
        },
        yAxis: {
          type: "value",
          scale: true,
          splitNumber: 3,
        },
        grid: {
          left: "15%",
        },
        tooltip: {
          trigger: "axis",
          formatter: "{c}人",
        },
        series: [
          {
            data: [418, 430, 458, 472, 542],
            itemStyle: {
              color: "#39A2FF",
            },
            type: "line",
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: "#ECF6FF", // 0% 处的颜色
                },
                {
                  offset: 0.4,
                  color: "#ABD4F8", // 100% 处的颜色
                },
                {
                  offset: 0,
                  color: "#69B9FF", // 100% 处的颜色
                },
              ]),
            },
          },
        ],
      };
      this.chart.setOption(option);
    },
    init3() {
      this.chart = echarts.init(this.$refs.chart3);
      let option = {
        legend: {
          top: "center",
          right: "1%",
          orient: "vertical",
          padding: [0.5, 0.5, 0.5, 0.5],
          itemGap: 5,
          textStyle: { fontSize: 14 },
        },
        color: ["#1492ff", "#ffd800", "#16a085", "#f39c11"],
        grid: {
          top: "middle",
          right: "center",
        },
        series: [
          {
            type: "pie",
            data: [
              { value: 12, name: "国家级" },
              { value: 70, name: "省级" },
              { value: 3, name: "省运会周期累计金牌数" },
              
            ],
            // radius: ["40%", "70%"],
            label: {
              formatter: "{@c}个 ({d}%)",
              fontSize: 16,
            },
          },
        ],
      };
      this.chart.setOption(option);
      this.chart.resize();
    },
    initMap() {
      var map = new AMap.Map("map", {
        viewMode: "3D",
        zoom: 13,
        center: [121.374146, 28.568495],
        resizeEnable: true,
      });
      var layer = new AMap.LabelsLayer({
        zooms: [3, 20],
        zIndex: 1000,
        // 开启标注避让，默认为开启，v1.4.15 新增属性
        collision: true,
        // 开启标注淡入动画，默认为开启，v1.4.15 新增属性
        animation: true,
      });

      map.add(layer);

      var markers = [];

      for (var i = 0; i < LabelsData.length; i++) {
        var curData = LabelsData[i];
        // curData.extData = {
        //   index: i+10,
        // };
        var labelMarker = new AMap.LabelMarker(curData);

        markers.push(labelMarker);

        layer.add(labelMarker);
      }
      map.setFitView();
    },
   handle(e) {
      if (e != 3) {
        this.indexA = e;
      }
      if (e == 1) {
        setTimeout(() => {
          this.initMap();
        }, 10);
      }
      if (e == 2) {
        setTimeout(() => {
          this.ref();
        }, 10);
      }
    },
    change(e) {
      if (e == 1) {
        this.period2 = this.period.slice(0, 3);
      } else if (e == 2) {
        this.period2 = this.period.slice(3, 6);
      }
    },
    btn(e, i) {
      if (i == 0) {
        this.dialogVisible = true;
        // this.init();
      } else if (i == 1) {
        this.dialogVisible2 = true;
        // this.init2();
      } 
    },
    btn2(e,v,t,i) {
      this.dialogMedal = true;
      this.title = e;
      this.totalTitle = v;
      this.oText = t;
    },
    btn3(e,v,t,i) {
      this.dialogVisible3 = true;
      this.title = e;
      this.totalTitle = v;
      this.oText = t;
    },
    btn4(e,v,t,i) {
      this.dialogVisible4 = true;
      this.title = e;
      this.totalTitle = v;
      this.oText = t;
    },
    ref() {
      this.chart3 = echarts.init(this.$refs.ref);
      let option = {
        color: "#1492FF",
        xAxis: {
          type: "category",
          data: [
            "新桥镇",
            "桐屿街道",
            "蓬街镇",
            "螺洋街道",
            "路桥街道",
            "路南街道",
            "路北街道",
            "金清镇（含滨海）",
            "峰江街道",
            "横街镇",
          ],
          axisLabel: {
            interval: 0,
            rotate: "40",
          },
        },
        tooltip: {
          trigger: "axis",
          formatter: "{c}平方米",
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [1.16, 2.5, 0.78, 2.26, 0.82, 0.8, 2.9, 0.96, 1.25, 1.52],
            type: "bar",
            barWidth: 20,
            markLine: {
              symbol: "none", //去掉警戒线最后面的箭头
              label: {
                position: "middle", //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
              },
              data: [
                {
                  silent: false, //鼠标悬停事件  true没有，false有
                  lineStyle: {
                    //警戒线的样式  ，虚实  颜色
                    type: "solid",
                    color: "red",
                  },
                  label: {
                    formatter: "国家平均值（2）",
                  },
                  yAxis: 2, // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                },
                {
                  silent: false, //鼠标悬停事件  true没有，false有
                  lineStyle: {
                    //警戒线的样式  ，虚实  颜色
                    type: "solid",
                    color: "orange",
                  },
                  label: {
                    formatter: "省平均值（2.4）",
                  },
                  yAxis: 2.4, // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                },
              ],
            },
          },
        ],
      };
      this.chart3.setOption(option);
    },
  },
};
</script>

<style lang="less">
.body {
  width: 100%;
  background: #ffffff;
  box-shadow: 2px 4px 12px 2px rgba(42, 82, 117, 0.06);
  border-radius: 4px;
  padding: 20px;
  .head {
    margin: 0 auto;
    width: 100%;
    height: 60px;
    background-image: linear-gradient(
      270deg,
      rgba(20, 146, 255, 0.81) 1%,
      #0085f8 100%
    );
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    .left {
      font-family: PingFangSC-Medium;
      font-size: 24px;
      color: #ffffff;
      line-height: 32px;
      font-weight: 500;
      font-size: 24px;
    }
    .right {
      font-size: 20px;
      opacity: 0.9;
      font-family: PingFangSC-Regular;
      font-size: 20px;
      color: #ffffff;
      text-align: right;
      line-height: 28px;
      font-weight: 400;
    }
  }
  .btn:hover {
    cursor: pointer;
  }
  .btn {
    width: 100%;
    height: 40px;
    background: #f7f7f7;
    border-radius: 6px;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    div {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-family: PingFangSC-Medium;
    }
    .active {
      background: #ffffff;
      border: 1px solid #1492ff;
      border-radius: 6px;
      color: #1492ff;
      font-weight: 600;
    }
  }
  .culture {
    width: 49%;
    height: 474px;
    border: 1px solid #9dd2ff;
    border-radius: 4px;
    margin-bottom: 16px;
    overflow: hidden;
    .title {
      width: 100%;
      height: 40px;
      background-color: #e7f4fe;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 18.6px;
      .titleDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          width: 4px;
          height: 20px;
          background: #1492ff;
          border-radius: 2px;
          margin-left: 12px;
        }
        div {
          font-size: 20px;
          margin-left: 8px;
          font-family: Helvetica;
          font-size: 20px;
          color: #000000;
          letter-spacing: 0;
          font-weight: 400;
        }
      }

      img {
        width: 19.948px;
      }
    }
    .common {
      width: 100%;
      padding: 21.948px;
      display: flex;
      .left {
        height: 199.485px;
        background-color: #f7f7f7;
        margin-left: 19.948px;
        padding: 0 19.948px;
        .up {
          width: 100%;
          height: 49.879px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 1.008px solid #ccc;
          img {
            width: 19.948px;
            height: 19.948px;
          }
          div {
            font-size: 19.964px;
            margin-left: 4.991px;
          }
        }
        .down {
          width: 100%;
          height: 139.639px;
          display: flex;
          justify-content: space-around;
          align-items: center;

          .index {
            display: flex;
            flex-direction: column;
            align-items: center;
            .num {
              margin-bottom: 19.948px;
              color: #6ab2fb;
              font-size: 29.931px;
            }
            .item {
              font-size: 17.965px;
            }
          }
        }
      }
    }
    .cultureItem {
      width: calc(100% - 32px);
      height: 118px;
      background: #ffffff;
      border: 1px solid #eeeeee;
      box-shadow: 0px 2px 8px 0px rgba(51, 51, 51, 0.05);
      border-radius: 8px;
      margin: 11px 16px 11px;
      padding: 16px;
      span:nth-child(1) {
        font-family: PingFangSC-Medium;
        font-size: 18px;
        color: #000000;
        letter-spacing: 0;
        line-height: 26px;
        font-weight: 500;
      }
      .span {
        width: 78px;
        height: 26px;
        float: right;
        background: #ffffff;
        border: 0.5px solid #1492ff;
        border-radius: 4px;
        font-family: PingFangSC-Medium;
        font-size: 14px;
        color: #1492ff;
        letter-spacing: 0;
        line-height: 20px;
        font-weight: 500;
        text-align: center;
        line-height: 26px;
        .iconfont {
          color: #1492ff;
        }
      }
      .span:hover {
        cursor: pointer;
      }
      div:nth-child(2) {
        font-family: PingFangSC-Medium;
        font-size: 18px;
        color: #1492ff;
        letter-spacing: 0;
        line-height: 28px;
        font-weight: 500;
        display: flex;
        align-items: center;
        .company {
          margin-left: 4px;
          font-family: PingFangSC-Regular;
          font-size: 16px;
          color: #666666;
          letter-spacing: 0;
          line-height: 24px;
          font-weight: 400;
        }
      }
    }
    .el-pagination {
      button {
        display: none;
      }
      .number {
        color: #59aaf5;
        background: #edf4ff;
      }
      .active {
        color: #ffffff;
        background: #1492ff;
      }
    }
  }
  .art {
    margin-top: 9.982px;
    border-radius: 7.982px;
    overflow: hidden;
    border: 0.992px solid #1191ff;
    .title {
      width: 100%;
      height: 47.895px;
      background-color: #e7f4fe;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 29.931px 0 9.982px;
      div {
        font-size: 15.965px;
        font-weight: 900;
        padding-left: 19.948px;
        border-left: 4.991px solid #1191ff;
      }
      img {
        width: 19.948px;
      }
    }
    .commonn {
      width: 100%;
      padding: 21.948px;
      display: flex;
      .data {
        width: 488.746px;
        height: 219.434px;
        background-color: #f7f7f7;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .dataItem {
          display: flex;
          justify-content: space-between;
          padding: 0 42.889px 0 19.948px;
          .left {
            display: flex;
            justify-content: center;
            align-items: center;
            .spot {
              width: 9.982px;
              height: 9.982px;
              border-radius: 50%;
              background-color: #058cfe;
              margin-right: 14.957px;
            }
            .item {
              font-size: 15.965px;
            }
          }
          .num {
            color: #058cfe;
            font-size: 15.965px;
          }
        }
      }
    }
  }
}
.el-dialog__wrapper {
  width: 100%;
  .el-dialog__header {
    .el-dialog__title {
      font-family: PingFangSC-Medium;
      font-size: 18px;
      color: #000000;
      letter-spacing: 0;
      line-height: 26px;
      font-weight: 500;
      border-left: 4px solid #1492ff !important;
      border-radius: 2px;
      padding-left: 8px;
    }
    .el-dialog__headerbtn {
      width: 26px;
      height: 26px;
      // background: #1492ff;
      border-radius: 50%;
      i {
        color: #000;
      }
    }
  }
}
.el-dialog__wrapper::-webkit-scrollbar {
  width: 0px;
}
.v-modal {
  width: 100% !important;
}
.el-dialog__body {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.dialogVisible5_title {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.dialogVisible5_box {
  width: 100%;
}
.dialogVisible5_t1 {
  flex: 5;
  color:#000;
  font-size:14px;

}
.dialogVisible5_t2 {
  flex: 1;
  color:#1492ff;
  font-size:12px
}
</style>