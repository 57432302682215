export var LabelsData = [
    {
        name: '路桥区螺洋街道岘头林村小康体育村',
        position: [121.364425,28.535342],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 10,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区螺洋街道岘头林村小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区金清镇前尚家村小康体育村',
        position: [121.470734,28.511845],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 16,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区金清镇前尚家村小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区金清镇沙头村小康体育村',
        position: [121.45793,28.494414],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 8,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区金清镇沙头村小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区金清镇双西村小康体育村',
        position: [121.509673,28.524586],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 23,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区金清镇双西村小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区蓬街镇尚家村小康体育村',
        position: [121.460985,28.535152],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 6,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区蓬街镇尚家村小康体育村',
            direction: 'left',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区峰江街道亭屿村小康体育村',
        position: [121.378762,28.54468],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区峰江街道亭屿村小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区峰江街道孙家村小康体育村',
        position: [121.39762,28.518437],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区峰江街道孙家村小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区峰江街道上陶村小康体育村',
        position: [121.394766,28.535969],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区峰江街道上陶村小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '桐屿街道建设村小康体育村',
        position: [121.325853,28.587621],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '桐屿街道建设村小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '桐屿街道桐杨居小康体育村',
        position: [121.333072,28.5886],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '桐屿街道桐杨居小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区路北街道洋洪村小康体育村',
        position: [121.380458,28.592786],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区路北街道洋洪村小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区桐屿街道盐岙村小康体育村',
        position: [121.327587,28.624757],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区桐屿街道盐岙村小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区桐屿街道民主村小康体育村',
        position: [121.309706,28.609108],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区桐屿街道民主村小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
        {
        name: '路桥区蓬街镇山下李村小康体育村',
        position: [121.454275,28.542463],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区蓬街镇山下李村小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区横街镇洋宅西村小康体育村',
        position: [121.441854,28.518289],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区横街镇洋宅西村小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区路南街道方家村小康体育村',
        position: [121.404115,28.559528],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区路南街道方家村小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区新桥镇东蓬林村小康体育村',
        position: [121.414684,28.51375],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区新桥镇东蓬林村小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区路桥街道新安小区小康体育村',
        position: [121.364127,28.571927],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区路桥街道新安小区小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区金清镇上塘村小康体育村',
        position: [121.514109,28.500204],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区金清镇上塘村小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区蓬街镇花门村小康体育村',
        position: [121.484686,28.569045],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区蓬街镇花门村小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区路北街道五洋社区多功能运动场',
        position: [121.3606,28.59183],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区路北街道五洋社区多功能运动场',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区中医院笼式足球场',
        position: [121.41626,28.554682],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区中医院笼式足球场',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区螺洋街道向北屋笼式足球场',
        position: [121.351247,28.5693],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区螺洋街道向北屋笼式足球场',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区路北街道洋叶村小康体育村',
        position: [121.371451,28.585768],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区路北街道洋叶村小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区桐屿街道下庄卢新村小康体育村',
        position: [121.353994,28.589416],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区桐屿街道下庄卢新村小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },

    {
        name: '路桥区蓬街镇塘浦村小康体育村',
        position: [121.46651,28.551359],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区蓬街镇塘浦村小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区横街镇陈家村小康体育村',
        position: [121.4579,28.522595],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区横街镇陈家村小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区路南街道张李村小康体育村',
        position: [121.389289,28.549786],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区路南街道张李村小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区路南街道应家村小康体育村',
        position: [121.410191,28.556324],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区路南街道应家村小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区新桥镇华章村小康体育村',
        position: [121.43067,28.515817],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区新桥镇华章村小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },

    {
        name: '路桥区路桥街道石浜村小康体育村',
        position: [121.374817,28.56937],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区路桥街道石浜村小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区金清镇高升村小康体育村',
        position: [121.515074,28.513505],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区金清镇高升村小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区金清镇新惠村小康体育村',
        position: [121.505312,28.539008],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区金清镇新惠村小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区螺洋街道芦阳社区（双庙）小康体育村',
        position: [121.330441,28.567115],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区螺洋街道芦阳社区（双庙）小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区峰江街道泾山村小康体育村',
        position: [121.368035,28.533555],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区峰江街道泾山村小康体育村',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区峰江街道蒋僧桥村社区多功能运动场',
        position: [121.400932,28.523439],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区峰江街道蒋僧桥村社区多功能运动场',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区路桥街道新路村社区多功能运动场',
        position: [121.389222,28.561946],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区路桥街道新路村社区多功能运动场',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '路桥区金清镇高升村社区多功能运动场',
        position: [121.515074,28.513505],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '路桥区金清镇高升村社区多功能运动场',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },

];